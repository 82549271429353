.promoWidget {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.promoWidgetTitle {
  position: relative;
  width: 1010px;
  font-size: 56px;
  text-align: center;
  text-transform: uppercase;
}

.promoWidgetText {
  font-size: 21px;
  max-width: 1010px;
  text-align: center;
  line-height: 30px;
  color: #ccc;
  padding: 60px 0;
  font-family: Roboto;
}

.promoWidgetClose {
  position: absolute;
  padding-left: 15px;
  font-size: 14px;
  left: 100%;
  top: 0;
  cursor: pointer;
  line-height: 12px;
  opacity: .8;
  will-change: opacity;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: "";
    background-image: url(../Filter/images/close.png);
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
  }
}

.promoWidgetFooter {
  display: flex;

  & > div {
    margin: 0 10px;
  }
}

.prevButton {
  position: relative;
  z-index: 1;
  display: inline-block;
  box-sizing: border-box;
  min-width: 250px;
  height: 60px;
  margin: 1px;
  padding: 0 25px;
  border: 3px solid rgba(51, 51, 51, 0.85);
  background: hsla(0,0%,100%,.2);
  border-radius: 0;
  box-shadow: 0 0 0 #fff;
  color: #fff;
  font-family: "Roboto Condensed", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  text-shadow: 0 -1px 2px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  letter-spacing: -.5px;
  transition: box-shadow .4s ease-out;
  touch-action: manipulation;
  will-change: box-shadow;
  cursor: pointer;
  outline: none;
  opacity: .85;

  &:hover {
    opacity: 1;
    border-color: rgb(51, 51, 51);
  }
}