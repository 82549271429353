.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
}

.wrapper:global(.extraLarge) {
  width: 400px;
  height: 400px;
}

.component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .image {
    background-image: var(--image);
    background-size: contain;
    width: 100%;
    height: 100%;
  }
}

.colorizable {
  -webkit-mask-image: var(--image);
  -webkit-mask-size: 100% 100%;
  .image {
    mix-blend-mode: overlay;
  }
}

.colorizable.background {
  .image {
    filter: brightness(0.7);
  }
}
