.nav {
    position: fixed;
    top: 160px;
    left: 0;
    z-index: 997;
}

:global [lang='es'],
[lang='fr'],
[lang='pt-br'],
[lang='es-ar'] {
    :local .nav {
        width: 220px;
    }
}

.link {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    margin: 0 0 2px;
    padding: 0 15px;
    background-image: url('./images/nav-default-bg.png');
    background-repeat: no-repeat;
    background-position: left center;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;

    &::before {
        background-image: url('./images/nav-current-bg.png');
        background-position: right center;
    }

    &::after {
        background-image: url('./images/nav-hover-bg.png');
    }

    &::before,
    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: left center;
        opacity: 0;
        content: '';
        transition: opacity .3s;
    }

    &.linkCurrent {
        background: none;
        color: #000;
        text-shadow: 0 0 1px rgba(255, 255, 255, .4);

        &::before {
            background-position: right center;
            opacity: 1;
        }
    }

    &:not(.linkCurrent):hover {
        background: none;
        color: #eee;

        &::after {
            opacity: 1;
        }
    }
}

.linkSpacer {
    margin-top: 36px;
}

.title {
    padding: 10px 0 9px;
    line-height: 16px;
}

.progressText {
    padding: 3px 0;
    color: #4f6265;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    text-shadow: 0 0 3px rgba(255, 255, 255, .2);

    .link:not(.linkCurrent) & {
        color: #789498;
        text-shadow: 0 0 1px rgba(0, 0, 0, .3);
    }
}

.tooltipText {
    padding: 10px 0 0;
}

.badge {
    height: 16px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    background-color: #c21d00;
    padding: 2px 4px;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    position: relative;
    top: -8px;
    left: 2px;
    box-sizing: border-box;
    min-width: 16px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, .3);
}

.badgeBigNumber {
    padding: 2px 4px;
}

.badgeNew {
    padding: 2px 4px;
}

.blurable {
    opacity: 1;
    transition: opacity .2s linear, filter .2s linear;
    pointer-events: all;
    filter: blur(0px);
}

.isBlured {
    opacity: .5;
    pointer-events: none;
    filter: blur(4px);
}
