@import "../../styles/constants";

.progressWrap {
  position: relative;
}

.progressCollectedInfo {
  display: flex;
  padding: 20px 0 5px;
}

.text,
.collectedInfo {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;

  & > span {
    margin: 0 7px;
  }

  & > span:first-child {
    margin-left: 0;
  }
}

.text {
  padding-right: 5px;
}

.gold {
  color: $colorGold;
}

.unique {
  color: $colorUnique;
}

.cursor {
  cursor: pointer;
}

.exclusiveCounterWrap {
  display: inline-block;
  opacity: .85;

  &:hover {
    opacity: 1;
  }
}

.plus {
  font-family: Roboto;
}