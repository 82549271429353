.searchWrap {
  position: relative;
  padding: 12px 0 0;
}

.searchInputWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.searchInput {
  box-sizing: border-box;
  height: 33px;
  width: 292px;

  padding: 8px 30px 8px 12px;
  
  border: 1px solid rgba(255, 255, 255, .2);
  opacity: 0.33;

  background: rgba(0, 0, 0, .2);
  outline: none;
  font-size: 14px;
  color: #fff;

  transition: opacity .2s ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid rgba(255, 255, 255, .6);
    opacity: 1;
  }

  &NotEmpty {
    opacity: 1;
  }
}

.resetSearch {
  position: absolute;
  right: 0;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  background-image: url(./images/close.png);
  cursor: pointer;
}

.searchAbsolute {
  position: absolute;
  top: 5px;
  right: 260px;
}