.section {
    position: relative;
    height: 100%;
    margin: 0 auto;
}

.description {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 20px 0;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    border-bottom: 1px solid #5c5856;
    box-sizing: border-box;
    z-index: 2;
}