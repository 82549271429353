.filterContent {
  position: absolute;
  top: 19px;
  right: -24px;
  cursor: pointer;

  @media screen and (max-width: 1280px){
    right: initial;
    left: -24px;
  }
}

.filterIcon {
  position: relative;
  width: 15px;
  height: 15px;
  opacity: .8;

  &.active,
  &:hover {
    opacity: 1;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: paused;
  }

  &:before {
    animation-name: settings-rotate1;
    background-image: url(./images/settings__big.svg);
    top: -3px;
    left: -5px;
    width: 15px;
    height: 15px;
  }

  &:after {
    animation-name: settings-rotate2;
    background-image: url(./images/settings__small.svg);
    top: 10px;
    left: 3px;
    width: 10px;
    height: 10px;
  }

  &:hover:before,
  &:hover:after,
  &.active:before,
  &.active:after {
    animation-play-state: running;
  }

  @keyframes settings-rotate1 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes settings-rotate2 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
}

.filterColumnBorder {
  border-top: 1px solid #232322;
  padding-top: 15px !important;
}

.filterContainer {
  position: absolute;
  top: 30px;
  right: 0;
  border: 1px solid #232322;
  padding-bottom: 10px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .85);
  z-index: 1;
  cursor: initial;
}

.filterColumn {
  padding: 0px 15px;
  box-sizing: border-box;
}

.filterColumnHeader {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 0 15px;
}

.filterColumnBody {
  display: flex;
  min-width: 173px;
  max-width: 400px;
  flex-wrap: wrap;
  flex-direction: row;
}

.filterItem {
  margin-right: 25px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  width: max-content;
}