@import "../../styles/constants";

.tooltipHeader {
  padding: 0 0 10px 0;
  display: flex;
  align-items: center;
}
.tooltipHeaderColumn {
  flex-direction: column;
}

.tooltipIcon {
  position: relative;
  min-width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;

  &.size130 {
    min-width: 130px;
    height: 80px;
  }
}

.tooltipInfoWrap {
  padding-left: 5px;
}

.bigIcon {
  min-width: 629px;
  height: 400px;
}

.tooltipIconWater {
  position: absolute;
  bottom: -5px;
  width: 100%;
  height: 28px;
  background-size: 100%;
  background-image: url(../../images/water.png);
  z-index: 1;
  opacity: .7;
}

.tooltipBigIconWater {
  height: 100px;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: .4;
}

.icon {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
}

.iconContain {
  background-size: contain;
}

.gold {
  color: $colorGold;
}

.unique {
  color: $colorUnique;
}

.footerText {
  position: relative;
  padding: 10px 16px;
  font-size: 14px;
  max-width: 350px;
  font-weight: 400;
  line-height: 20px;

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
    opacity: .3;
    content: '';
  }

  &.top:after {
    bottom: initial;
    top: 0;
  }

  &.lineHeight25 {
    line-height: 25px;
  }

  & span:before {
    top: 7px !important;
  }
}

.notEnabled span:before {
    top: 5.7px !important;
}

.footerText > div[class^='Tooltip'] {
  padding: 0 20px 0 20px;

  &::before {
    left: 0px;
    top: 3px;
  }

  &::after {
    border: 0 !important;
  }
}

.tooltipIconType {
  position: relative;
  left: 2px;
  vertical-align: top;
}

.tooltip {
  position: relative;
}

.tooltipRare:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(./images/light_tooltip.png);
  background-size: 100% 100%;
}

.itemTypeMark {
  &.crews {
    &.advanced,
    &.unique {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: -10px;
      right: 0;
    }

    &.advanced {
      background-image: linear-gradient(65deg, transparent 56%, rgba(19, 114, 204, 0.49));
    }

    &.unique {
      background-image: linear-gradient(65deg, transparent 56%, rgba(225, 153, 42, 0.41));
    }
  }
}

.infoWrap {
  position: relative;
  left: -10px;
}

.iconType {
  position: relative;
  display: inline-block;
  min-width: 22px;
  max-width: 22px;
  height: 21px;
  margin: 0 5px 0px 5px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.tooltipCategory {
  font-size: 15px;
  padding: 2px 0 0 5px;
}

.tooltipBigCategory {
  padding-left: 4px;
  padding-top: 3px;
  font-size: 16px;
}

.tooltipInfo {
  position: relative;
  padding-left: 5px;
  font-size: 19px;
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.tooltipBigInfo {
  font-size: 18px;
}

.tooltipTitle {
  display: inline-block;
  vertical-align: middle;
}

.body {
  padding: 12px 15px 5px;
}

.isEnabledIcon {
  position: relative;
  padding-left: 30px;

  &:before {
    content: '';
    position: absolute;
    left: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    width: 14px;
    height: 11px;
    background-size: 100%;
    background-image: url(../../images/enabled.png);
  }
}

.tooltipBody {
  padding-right: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.tooltipBigBody {
  max-width: 600px !important;
  font-size: 16px;
  line-height: 21px;
  padding-left: 3px;
}

.defaultTooltip {}

.defaultTooltipHeader {
  position: relative;
  padding: 13px;
  font-weight: 700;
  font-size: 16px;

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
    opacity: .3;
    content: '';
  }
}

.defaultTooltipBody {
  padding: 13px;
  max-width: 350px;
  font-size: 14px;
}

.footer {
  font-size: 14px;
}

.tableData {
  padding: 13px;
}

.tableCell {
  padding: 5px 0;
  display: flex;
  min-width: 200px;
  justify-content: space-between;
}

.tableCellInfo {
  color: #fff;
}

.ttcWrap {

}

.ttcLine {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 30px;
  padding: 0 16px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(37, 34, 31);

  &:last-child {
    border-bottom: none;
  }
}

.ttcLineProgress {
  position: absolute;
  left: 0;
  height: 30px;
  background-color: #417d75;
  z-index: -1;

  &:after {
    content: "";
    position: absolute;
    left: 100%;
    border-right: 1px solid #74b8a9;
    height: 30px;
  }
}
.ttcLineTitle {
  color: #ddd;
}
.ttcLineValue {}