@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-style: normal;
    src: url('./fonts/RobotoCondensed-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-style: normal;
    src: url('./fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Bold-webfont.woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Regular-webfont.woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Light-webfont.woff');
    font-weight: 300;
    font-style: normal;
}