@import "../../styles/constants";

.wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: min-content;
  height: calc(100vh - 18px);
  transform: translateX(-50%);
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  z-index: 999;
}

.column {
  position: relative;
  text-align: center;
}

.allInfoCount {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 80px;
  text-transform: uppercase;
}

.text {
  color: #fff;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

.gold {
  color: $colorGold;
}

.helm {
  position: relative;
  width: 5vw;
  height: 12vh;
  display: inline-block;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5vw;
    height: 12vh;
    background-image: url(../../images/shadow_label_progress.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  &.helm1 {
    background-image: url(./images/progress_1.png);
  }

  &.helm2 {
    background-image: url(./images/progress_2.png);
  }

  &.helm3 {
    background-image: url(./images/progress_3.png);
  }

  &.helm4 {
    background-image: url(./images/progress_4.png);
  }

  &.helm5 {
    background-image: url(./images/progress_5.png);
  }
}