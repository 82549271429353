.activeDogTag {
  position: absolute;
  top: 57%;
  left: 59%;
  width: 5%;
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: drop-shadow(2px 4px 6px black);
  z-index: 1;
}

.component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
}

.colorizable {
  -webkit-mask-image: var(--image);
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;

  img {
    mix-blend-mode: overlay;
  }
}

.colorizable.background {
  img {
    filter: brightness(0.7);
  }
}
