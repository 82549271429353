.back {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    padding-left: 25px;
    transition: color .15s linear;
    cursor: pointer;

    &:hover {
        color: #fff;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        -webkit-mask-image: url(../../images/icon_arrow_back.svg);
        -webkit-mask-size: 16px 16px;
        background-color: rgba(255, 255, 255, .55);
        transition: background-color .15s linear;
    }

    &:hover:before {
        background-color: #fff;
    }
}