.dogTagClanTag {
    --translate: translate(-50%, -50%);
    --scale: scale(calc(var(--vw) / 4500));
    position: absolute;
    transform: var(--translate) var(--scale);
    display: flex;
}

.symbol {
    display: inline-block;
    -webkit-mask-image: url(./symbols/symbolsMedium.png);
    -webkit-mask-repeat: no-repeat;
}

.symbolImage {
    display: inline-block;
    mix-blend-mode: overlay;
    background-image: url(./symbols/symbolsMedium.png);
    width: 100%;
    height: 100%;
}