.container {
  overflow-y: auto;
  min-width: 1000px;
  max-width: 1300px;
  padding: 0 260px;
  margin: 0 auto;
}

.backgroundWrap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.background {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: #240d01;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;

  &.image {
    background-image: url(./images/background-room.jpg);
  }

  &::after {
    content: "";
    position: absolute;
    margin-top: 56.25%;
    width: 100%;
    height: 100%;
    background-color: #000;
  }
}

:global(body.realm-cn),
:global(body.realm-cn360),
:global(body.realm-t360)
{
    .background.image {
        background-image: url(./images/background-room-cn.jpg);
    }
}

.backgroundItem {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  pointer-events: none;
  transition: all 1.5s linear;
  z-index: 1;
  will-change: transition, background-image;
}

.backgroundShadows {
  top: -140px;
  height: calc(100% + 140px);
  box-shadow: inset 0 0 113px 85px rgba(0, 0, 0, .9);
}

.vehicleItem-10 {
  background-image: url(./images/vehicles/vehicles-10.png);
}

.vehicleItem-20 {
  background-image: url(./images/vehicles/vehicles-20.png);
}

.vehicleItem-30 {
  background-image: url(./images/vehicles/vehicles-30.png);
}

.vehicleItem-40 {
  background-image: url(./images/vehicles/vehicles-40.png);
}

.vehicleItem-50 {
  background-image: url(./images/vehicles/vehicles-50.png);
}

.vehicleItem-60 {
  background-image: url(./images/vehicles/vehicles-60.png);
}

.vehicleItem-70 {
  background-image: url(./images/vehicles/vehicles-70.png);
}

.vehicleItem-80 {
  background-image: url(./images/vehicles/vehicles-80.png);
}

.vehicleItem-90 {
  background-image: url(./images/vehicles/vehicles-90.png);
}

.vehicleItem-100 {
  background-image: url(./images/vehicles/vehicles-100.png);
}


.crewItem-10 {
  background-image: url(./images/crews/crews-10.png);
}

.crewItem-20 {
  background-image: url(./images/crews/crews-20.png);
}

.crewItem-30 {
  background-image: url(./images/crews/crews-30.png);
}

.crewItem-40 {
  background-image: url(./images/crews/crews-40.png);
}

.crewItem-50 {
  background-image: url(./images/crews/crews-50.png);
}

.crewItem-60 {
  background-image: url(./images/crews/crews-60.png);
}

.crewItem-70 {
  background-image: url(./images/crews/crews-70.png);
}

.crewItem-80 {
  background-image: url(./images/crews/crews-80.png);
}

.crewItem-90 {
  background-image: url(./images/crews/crews-90.png);
}

.crewItem-100 {
  background-image: url(./images/crews/crews-100.png);
}

.dollsItem-10 {
  background-image: url(./images/dolls/dolls-10.png);
}

.dollsItem-20 {
  background-image: url(./images/dolls/dolls-20.png);
}

.dollsItem-30 {
  background-image: url(./images/dolls/dolls-30.png);
}

.dollsItem-40 {
  background-image: url(./images/dolls/dolls-40.png);
}

.dollsItem-50 {
  background-image: url(./images/dolls/dolls-50.png);
}

.dollsItem-60 {
  background-image: url(./images/dolls/dolls-60.png);
}

.dollsItem-70 {
  background-image: url(./images/dolls/dolls-70.png);
}

.dollsItem-80 {
  background-image: url(./images/dolls/dolls-80.png);
}

.dollsItem-90 {
  background-image: url(./images/dolls/dolls-90.png);
}

.dollsItem-100 {
  background-image: url(./images/dolls/dolls-100.png);
}


.ensignsItem-10 {
  background-image: url(./images/ensigns/ensigns-10.png);
}

.ensignsItem-20 {
  background-image: url(./images/ensigns/ensigns-20.png);
}

.ensignsItem-30 {
  background-image: url(./images/ensigns/ensigns-30.png);
}

.ensignsItem-40 {
  background-image: url(./images/ensigns/ensigns-40.png);
}

.ensignsItem-50 {
  background-image: url(./images/ensigns/ensigns-50.png);
}

.ensignsItem-60 {
  background-image: url(./images/ensigns/ensigns-60.png);
}

.ensignsItem-70 {
  background-image: url(./images/ensigns/ensigns-70.png);
}

.ensignsItem-80 {
  background-image: url(./images/ensigns/ensigns-80.png);
}

.ensignsItem-90 {
  background-image: url(./images/ensigns/ensigns-90.png);
}

.ensignsItem-100 {
  background-image: url(./images/ensigns/ensigns-100.png);
}


.permoflagesItem-10 {
  background-image: url(./images/permoflages/permoflages-10.png);
}

.permoflagesItem-20 {
  background-image: url(./images/permoflages/permoflages-20.png);
}

.permoflagesItem-30 {
  background-image: url(./images/permoflages/permoflages-30.png);
}

.permoflagesItem-40 {
  background-image: url(./images/permoflages/permoflages-40.png);
}

.permoflagesItem-50 {
  background-image: url(./images/permoflages/permoflages-50.png);
}

.permoflagesItem-60 {
  background-image: url(./images/permoflages/permoflages-60.png);
}

.permoflagesItem-70 {
  background-image: url(./images/permoflages/permoflages-70.png);
}

.permoflagesItem-80 {
  background-image: url(./images/permoflages/permoflages-80.png);
}

.permoflagesItem-90 {
  background-image: url(./images/permoflages/permoflages-90.png);
}

.permoflagesItem-100 {
  background-image: url(./images/permoflages/permoflages-100.png);
}


.commonItem-10 {
  background-image: url(./images/common/common-10.png);
}

.commonItem-20 {
  background-image: url(./images/common/common-20.png);
}

.commonItem-30 {
  background-image: url(./images/common/common-30.png);
}

.commonItem-40 {
  background-image: url(./images/common/common-40.png);
}

.commonItem-50 {
  background-image: url(./images/common/common-50.png);
}

.commonItem-60 {
  background-image: url(./images/common/common-60.png);
}

.commonItem-70 {
  background-image: url(./images/common/common-70.png);
}

.commonItem-80 {
  background-image: url(./images/common/common-80.png);
}

.commonItem-90 {
  background-image: url(./images/common/common-90.png);
}

.commonItem-100 {
  background-image: url(./images/common/common-100.png);
}

.cat {
  background-image: url(./images/cat.png);
}

.light {
  background-image: url(./images/FX.png);
  will-change: opacity;
}

.masks {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.maskItem {
  position: absolute;
  background-color: transparent;
  cursor: initial;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  will-change: opacity, transition;
}

.maskCrews {
  left: 72.9%;
  top: 23%;
  width: 17%;
}

.maskVehicles {
  left: 15.5%;
  top: 41%;
  width: 31%;
}

.maskEnsigns {
  width: 12.6%;
  left: 52%;
  top: 29%;
}

.progressCrews {
  transform: rotate3d(40, 106, 65, -7deg);
}

.progressVehicles {
  transform: rotate3d(40, 106, 65, -4deg);
}

.progressEnsigns {
  transform: rotate3d(40, 106, 65, -7deg);
}

.progressPermoflages {
  transform: rotate3d(40, 106, 65, 3deg);
}

.progressDolls {
  transform: rotate3d(40, 106, 65, 4deg);
}

.maskPermoflages {
  width: 15.8%;
  left: 49%;
  top: 48%;
}

.maskDolls {
  width: 12.3%;
  left: 54.9%;
  top: 65%;
}

.svgContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  & > svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.textProgressBar {
  fill: #fff;
  font-weight: bold;
  font-size: 32px;
}

.textProgressBarTransform {
  transform: rotate3d(40, 106, 65, -7deg);
}

.textProgressBarDollsTransform {
  transform: rotate3d(40, 106, 65, 3deg);
}

.textProgressBarPermoflagesTransform {
  transform: rotate3d(40, 106, 65, 2deg);
}

.svgMasks > path {
  opacity: 0;
  cursor: pointer;
  fill: rgba(255, 255, 255, .4);
  will-change: opacity, transition;
}

.svgG {
  cursor: pointer;
  opacity: 0;
  will-change: opacity, transition;
}

.hoverDisabled {

}

.svgMasks > path:not(.hoverDisabled):hover {
  opacity: 1 !important;
}

.svgMasks > g.svgG:hover {
  opacity: 1 !important;
}

.devMode {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
}

.devModeWrapper {
  background-color: #232322;
  color: #fff;
  border-radius: 4px;
}

.svgProgressEnsigns {
  transform: rotateZ(-2deg) !important;
}

.svgProgressCrews {
  transform: rotateZ(-4deg) !important;
}

.svgProgressDolls {
  transform: rotateZ(2deg) !important;
}

.svgProgressPermoflages {
  transform: rotateZ(1.4deg) !important;
}

.svgProgress {
  opacity: 0;
  pointer-events: none;
  transition: all .5s linear;
  will-change: opacity, transition;

  & > path {
    transition: all .5s linear;
  }

  & rect {
    transition: all 1.5s ease-in-out;
    will-change: width, transition;
  }
}

.pathProgressBg {
  stroke: #20292b;
}

.pathProgressLine {
  stroke: rgb(255, 204, 101);
}

.snowWindow {
  position: absolute;
  width: 3.6%;
  height: 7%;
  z-index: 9;
  border-radius: 50%;
  top: 22.4%;
  right: 26.9%;
  background-color: #000f33;
  box-shadow: 0 0 53px 29px rgba(255, 255, 255, .4);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 8px 1px 29px 7px rgba(255, 255, 255, 0.6);
    border-radius: 50%;
  }

  & > canvas {
    position: absolute;
    top: -25%;
    left: -25%;
    border-radius: 50%;
  }
}

.zipper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
}

.test {
  position: absolute;
  width: 521px;
  height: 328px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  top: 13%;
  left: 71.5%;
  z-index: 999;
}

.svgProgressWrap {
  pointer-events: none;
}

.st0 {
  filter: url(#crewsMaskFilter);
}
.st1 {
  fill:url(#crewsMaskGradient);
}
.st2 {
  mask:url(#crewsMask);
  fill:#FFFFFF !important;
  stroke:#FFFFFF !important;
  stroke-width:3;
  stroke-miterlimit:10;
}

.textProgressBarCrews {
  transform: rotate(-4deg) skew(-5deg, 0deg);
}

.textProgressBarPermoflages {
  transform: rotate(1deg) skew(-6deg, 0deg);
}

.textProgressBarVehicles {
  transform: rotate(-1deg) skew(1deg, 0deg);
}

.textProgressBarDolls {
  transform: rotate(2deg);
}

.textProgressBarEnsigns {
  transform: rotate(-4deg) skew(-4deg, 0deg);
}

.crewsProgressSt0{display:none;}
.crewsProgressSt1{display:inline;fill:url(#crewsProgressGradient);}
.crewsProgressSt2{display:inline;fill:url(#crewsProgressGradient2);}
.crewsProgressSt3{opacity:0.5;fill:url(#crewsProgressGradient3);}
.crewsProgressSt4{opacity:0.49;stroke:#FFFFFF;stroke-miterlimit:10;}
.crewsProgressSt5{clip-path:url(#crewsProgressClipPath);fill:#FFCC66;transition: all .25s linear}
.crewsProgressSt6{opacity:0.42;fill:url(#crewsProgressGradient6);}

.permoProgressSt0{opacity:0.42;fill:url(#permoProgressGradient);}
.permoProgressSt1{opacity:0.49;stroke:#FFFFFF;stroke-miterlimit:10;}
.permoProgressSt2{clip-path:url(#permoProgressClip);fill:#FFCC66;transition: all .25s linear}
.permoProgressSt3{opacity:0.42;fill:url(#permoProgressGradient1);}

.permoMaskSt0{filter:url(#permoflagesFilter);}
.permoMaskSt1{opacity:0.5;fill:#FFFFFF;}
.permoMaskSt2{mask:url(#permoflagesFilterMask1);fill:url(#permoflagesFilterGradient1) !important;}
.permoMaskSt3{filter:url(#permoflagesFilter1);}
.permoMaskSt4{mask:url(#permoflagesFilterMask2);fill:url(#permoflagesFilterGradient2) !important;}
.permoMaskSt5{fill:none;}

@media screen and (width: 1280px) and (height: 960px) {
  .maskEnsigns {
    top: 27%;
  }

  .maskCrews {
    left: 74.4%;
    top: 20%;
    width: 17%;
  }

  .maskPermoflages {
    width: 15%;
    left: 49.5%;
    top: 46%;
  }

  .maskVehicles {
    left: 13.5%;
    top: 39%;
    width: 32.7%;
  }

  .maskDolls {
    width: 13.3%;
  }
}

@media screen and (width: 1600px) and (height: 900px) {
  .maskCrews {
    left: 74%;
    top: 22%;
  }

  .maskVehicles {
    top: 40%;
  }

  .maskDolls {
    width: 13.3%;
    left: 55%;
    top: 66%;
  }
}

@media screen and (width: 1896px) and (height: 975px) {
  .maskEnsigns {
    width: 12%;
    left: 52%;
    top: 27%;
  }

  .maskCrews {
    left: 72.2%;
    top: 20%;
    width: 16%;
  }

  .maskPermoflages {
    width: 15%;
    left: 49.1%;
    top: 44.4%;
  }

  .maskVehicles {
    left: 16.5%;
    top: 38%;
    width: 30%;
  }

  .maskDolls {
    width: 12.1%;
    left: 54.5%;
    top: 60.9%;
  }
}

@media screen and (width: 1920px) and (height: 1080px) {
  .maskEnsigns {
    width: 13.6%;
  }

  .maskCrews {
    left: 74.5%;
  }

  .maskPermoflages {
    width: 16%;
    left: 49.1%;
    top: 49.4%;
  }

  .maskVehicles {
    left: 13.5%;
    top: 41%;
    width: 32.8%;
  }

  .maskDolls {
    width: 12.3%;
    left: 55.4%;
    top: 67.4%;
  }
}

@media (min-aspect-ratio: 21/9) {
  .svgContainer > svg {
    height: min-content;
  }

  .backgroundItem,
  .background {
    background-size: initial;
  }

  .background {
    background-color: #150803;
  }

  .activeDoll {
    top: 36.2%;
    left: 56.5%;
    width: 2%;
    height: 6%;
  }
}