@import "../../styles/constants";

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 228px;
  height: 112px;
  transition: all 0.2s ease-out;
  &.notOwned {
    opacity: 0.2;
    mix-blend-mode: luminosity;
  }
  &.withPreview:hover {
    opacity: 1;
    mix-blend-mode: normal;
  }
}