@import "../../styles/constants";

.filterWrap {
  position: relative;
  padding: 10px 10px 0 0;
}

.filterItem {
  margin-right: 25px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  width: max-content;

  label input:checked ~ div[class*=caption] {
    color: #fff !important;
  }

}

.filterLabel {
  display: flex;
  position: relative;
  padding-left: 25px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  height: 36px;
  cursor: pointer;
  opacity: .9;

  &:hover {
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 19px;
    height: 18px;
    background-image: url(./images/filter.png);
  }
}

.filterHeaderCheckboxItem {
  position: relative;
  top: 6px;
  margin-left: 10px;
}

.label {
  color: #fff;
  line-height: 22px;
  cursor: pointer;
}

.selectWrap {
  margin-right: 15px;
}

.selectWrapCheckbox {
  padding: 10px 5px 0;
}

.checkboxDisabled {
  opacity: .3;
}

.filterContainer {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: space-between;
  width: min-content;
  border: 1px solid #232322;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .85);
  z-index: 1;
  cursor: initial;
}

.filterColumn {
  padding: 0 0 10px;
  box-sizing: border-box;

  &:first-child,
  &:last-child {
    padding: 0 15px 10px;
  }

  &.borderLeft {
    border-left: 1px solid #232322;
  }
}

.filterColumnHeader {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 45px;
}

.filterColumnBody {
  display: flex;
  min-width: 145px;
  max-width: 400px;
  flex-wrap: wrap;
  flex-direction: row;

  &.width175 {
    min-width: 175px;
  }

  &.filterColumnBodyLevel {
    justify-content: flex-end;
  }
}

.flexColumn {
  flex-direction: column;
}

.filterCheckboxItem {
  margin-right: 25px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  width: max-content;
}

div[class^='CheckboxWithLabel__container'] {
  label {
    cursor: pointer !important;
  }
}

.filterNation {
  position: relative;
  display: inline-block;
  top: 2px;
  width: 27px;
  height: 27px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: var(--backgroundImage);
}

.filterTypeIcon {
  position: relative;
  display: inline-block;
  top: -1px;
  width: 35px;
  height: 27px;
  background-repeat: no-repeat;
  background-size: contain;
}

.complexityIconsWrapper {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: -2px;

  &.activeComplexityIconsWrapper {
    margin: 0 5px 1px 5px;
  }
}

.complexityIconWrapper {
  width: 13px;
  height: 13px;

  background-repeat: no-repeat;
  background-size: 13px 13px;
  background-position: center;
}

.filterCrewTypeWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rarityIcon {
  margin-left: -2px;
  margin-right: 6px;
  width: 24px;
  height: 24px;

  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center;
}

.test {
  color: #fff;
}

.activeFilters {
  display: flex;
  padding-left: 12px;
  align-items: center;
  flex-wrap: wrap;
  line-height: 32px;
}

.filterSearchCounter {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #ccc;
  transition: color .5s linear;
  line-height: 34px;

  &:hover {
    color: #fff;
  }
}

.resetFilters {
  position: relative;
  height: min-content;
  color: #ccc;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  line-height: 32px;
  padding: 0 5px;
  border: 1px solid transparent;
  box-shadow: inset 0 0 0 1px transparent;
  transition: border .25s, box-shadow .25s;

  &:hover {
    border-color: rgba(0, 0, 0, .3);
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(131, 156, 158, .6);
    color: #fff;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 5px;
    background-size: 100%;
    background-image: url(./images/close.png);
    vertical-align: middle;
  }
}

.activeFilterNation {
  position: relative;
  width: 27px;
  height: 27px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 5px;
  background-image: var(--backgroundImage);
}

.activeFilterType {
  position: relative;
  width: 31px;
  height: 27px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0;
  cursor: pointer;
}

.activeItemFilter {
  margin: 0 5px;
  color: #fff;
  font-size: 18px;
  opacity: .7;

  &:first-child {
    margin: 0 0;
  }

  &:last-child {
    margin-right: 10px;
  }
}

.filterHeader {
  position: relative;
  display: flex;
  flex-direction: column;
}

.filterHeaderWrap {
  position: relative;
  display: flex;

  & > div:first-child {
    margin-left: 0px;
  }
}