.app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: opacity 0.2s ease-out;
    background-image: url(../../images/background.jpg);
    background-color: #060302;
    background-repeat: no-repeat;
    background-size: cover;
}

.backButton {
    position: fixed;
    top: 0;
    left: 6vw;
    z-index: 10000;
    opacity: 0;
    pointer-events: none;
}

:global(.portPage) {
    .app {
        opacity: 0;
        pointer-events: none;
    }

    .backButton {
        opacity: 1;
        pointer-events: auto;
    }
}